import React from 'react';

import PortfolioItemLayout from '../../components/portfolio/PortfolioItemLayout';

import d1 from '../../assets/covid/deskResearch/d1.png';
import d2 from '../../assets/covid/deskResearch/d2.png';

import c1 from '../../assets/covid/concept/c1.png';
import c2 from '../../assets/covid/concept/c2.png';
import c3 from '../../assets/covid/concept/c3.png';

import p1 from '../../assets/covid/personas/p1.png';
import p2 from '../../assets/covid/personas/p2.png';
import p3 from '../../assets/covid/personas/p3.png';
import p4 from '../../assets/covid/personas/p4.png';
import p5 from '../../assets/covid/personas/p5.png';

import m1 from '../../assets/covid/mockups/m1.png';
import m2 from '../../assets/covid/mockups/m2.png';

const Covid = () => (
    <PortfolioItemLayout
        mainImgSrc="https://s3.amazonaws.com/images.hamlethub.com/hh20mediafolder/2514/201406/iStock_000016476123Small.jpg"
        title="CovidVolunteer"
        subtitle="To help people help people"
        imgPosition="50% 60%"
        // listing
        sector="Charity"
        myRole="UX and UI designer in a team of five"
        technology="Axure"
        projectTime="one week"
        // First section
        firstSectionTitle="We can beat pandemic together"
        firstSectionDescription="The pandemic situation in Poland and in the world has caused many problems. Lots of people are afraid for their health and life. However, despite the situation, many people want to help their neighbors and family. Many NGOs want to help. In order to facilitate the work of volunteers and positively influence their contact with people who need help, I have created a solution that could solve many problems."
        // rest
        contentSections={[
            {
                title: 'Desk research',
                description: "I had good start thanks to previous conversations with people, patients, families. After conducting desk research I was able to obtain information about problems faced by volunteers. Result of research was quite simple - the best solution for volunteers would be a mobile application.",
                images: [
                    { original: d1 },
                    { original: d2 },
                ]
            },
            {
                title: 'Conceptual phase',
                description: "Two groups emerged during conceptual phase: people in need and volunteers. However, in order for it to fulfill its purpose, the app should be addressed to volunteers. With help of the mobile application they would have easy access to the people in need and task management would be simplified as well.",
                images: [
                    { original: c1 },
                    { original: c2 },
                    { original: c3 },
                ]
            },
            {
                title: 'Personas & Scenarios',
                description: 'Desk research allowed to create personas and user scenarios.',
                images: [
                    { original: p1 },
                    { original: p2 },
                    { original: p3 },
                    { original: p4 },
                    { original: p5 },
                ]
            },
            {
                title: 'Mockups',
                description: "Based on the personas and user scenarios and previous research, I created a lo-fi mock-up in Axure. The mockups present the individual steps of the users.",
                images: [
                    { original: m1 },
                    { original: m2 },
                ]
            },
        ]}
        nextLink="/portfolio/micro"
    />
)

export default Covid;